<template>
    <v-row justify="center">
        <v-dialog :value="value" persistent max-width="560">
            <v-card>
                <v-card-title class="text-h5">
                    Add my client
                </v-card-title>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="First name *" outlined dense prepend-inner-icon="mdi-account"
                                v-model="c.firstname" type="text">
                            </v-text-field>
                            <v-text-field label="Last name *" outlined dense prepend-inner-icon="mdi-account"
                                v-model="c.lastname" type="text">
                            </v-text-field>
                            <v-text-field label="Email Address*" outlined dense prepend-inner-icon="mdi-at"
                                v-model="c.email" type="email">
                            </v-text-field>
                            <v-text-field v-if="type == 'funeral'" label="Representative*" outlined dense prepend-inner-icon="mdi-at"
                                v-model="representative" type="email">
                            </v-text-field>
                            <v-autocomplete :items="get_status" item-text="name" item-value="id" label="Status *"
                                outlined dense prepend-inner-icon="mdi-text" v-model="c.status">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="cancel_dialog_add_client">
                        Cancel
                    </v-btn>
                    <v-btn v-if="!adding_client" color="#1976D2" dark class="pl-10 pr-10" @click="add_client_fn">
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Add client
                    </v-btn>
                    <v-btn v-else color="#1976D2" disabled class="pl-10 pr-10">
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Add client
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'value', 'type'
    ],
    data: () => ({
        c: {
            firstname: null,
            lastname: null,
            email: null,
            status: null
        },
        adding_client: false,
        representative: null,
    }),
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_status: 'fd_client/get_status',
            fd_data: 'auth/get_user'
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        cancel_dialog_add_client() {
            this.$emit('cancel_dialog_add_client')
        },
        async add_client_fn() {
            let tp = {
                firstname: this.c.firstname,
                lastname: this.c.lastname,
                email: this.c.email,
                status: this.c.status,
                fd_id: this.fd_data.id,
                representative: this.representative
            }
            this.adding_client = true
            this.showSnackBar("Please wait...")
            await this.$axios.post('/auth/fd_client/create_client', tp)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    this.adding_client = false
                    if (data.response) {
                        this.cancel_dialog_add_client()
                        this.$store.dispatch('fd_client/get_clients')
                    }
                })
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>