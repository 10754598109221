<template>
    <v-container fluid>
        <v-skeleton-loader v-if="invoice == null" type="card"></v-skeleton-loader>
        <v-row justify="center" align="center" v-else>
            <v-col cols="12">
                <v-btn color="#1976D2" dark class="pl-10 pr-10 mt-3" @click="print()">
                    <v-icon class="mr-2">mdi-content-save</v-icon>
                    Download
                </v-btn>
            </v-col>
            <v-card style="padding-left:50px;padding-right:50px;margin-top:50px;margin-bottom:50px;" elevation="0"
                id="invoice">
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="3">
                            <v-img :src="invoice.user.get_user_add_info.get_fd_info.company_logo_base64"></v-img>
                        </v-col>
                        <v-col cols="1"></v-col>
                        <v-col cols="6">
                            {{ invoice.user.get_user_add_info.get_fd_info.address }}
                            <br>
                            Tel: {{ invoice.user.get_user_add_info.get_fd_info.mobile }}
                        </v-col>
                        <v-col cols="2"></v-col>
                        <v-col cols="12">
                            <p>Name of Deceased: {{ invoice.user.name }}</p>
                            <p>Date of Death: {{ $date(invoice.user.date_of_death).format('DD/MM/YYYY') }}</p>
                            <p>Account To: {{ invoice.user.representative ?? 'Reps of ' + invoice.user.name }}</p>
                            <p>Address: {{ invoice.user.address }}</p>
                            <p>Date of Invoice: {{ $date().format('DD/MM/YYYY') }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <b>Funeral Director Services</b>
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            <b>Amount</b>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in invoice.funeral_director_payments"
                        :key="'funeral_director_payments' + index">
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            {{ item.name }}
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ item.amount }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                        </v-col>
                        <v-col cols="4" v-if="invoice.funeral_director_payments.length > 0" style="text-align:right;">
                            Subtotal: €{{ subtotal1 }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <b>Payments Made on Your Behalf</b>
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            <b>Amount</b>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in invoice.payments_made" :key="'payments_made' + index">
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            {{ item.name }}
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ item.amount }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                        </v-col>
                        <v-col cols="4" v-if="invoice.payments_made.length > 0" style="text-align:right;">
                            Subtotal: €{{ subtotal2 }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            <b>Total: €{{ total }}</b>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            Professional Service Fee Includes:
                            <p>Advising on arrangements, liaising with embalmers, clergy, local media and grave diggers
                            </p>
                            <p>24 hr Reception/phone attendance and availability of funeral director.</p>
                            <p>Preparing payments for clergy, choir, gratuities and other related charges</p>
                            <p>Bank Details: {{ invoice.user.get_user_add_info.get_fd_info.bank_details }}</p>
                            <p>Credit Card or Cheques made payable to {{
                                invoice.user.get_user_add_info.get_fd_info.company_name }}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import html2pdf from 'html2pdf.js'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        api_url: null,
        invoice: null,
    }),
    async mounted() {
        await this.getData()
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        subtotal1() {
            let subtotal = 0
            if (this.invoice != null) {
                this.invoice.funeral_director_payments.forEach(payment => {
                    subtotal += parseFloat(payment.amount)
                })
            }
            return subtotal
        },
        subtotal2() {
            let subtotal2 = 0
            if (this.invoice != null) {
                this.invoice.payments_made.forEach(payment => {
                    subtotal2 += parseFloat(payment.amount)
                })
            }
            return subtotal2
        },
        total() {
            return this.subtotal1 + this.subtotal2
        },
    },
    methods: {
        async getData() {
            await this.$axios.get('/non_auth/decode_invoice_id', { data: this.$router.currentRoute.params.token })
                .then(({ data }) => {
                    if (data.response) {
                        this.invoice = data.data
                        this.invoice.funeral_director_payments = JSON.parse(data.data.funeral_director_payments)
                        this.invoice.payments_made = JSON.parse(data.data.payments_made)
                        console.log(this.invoice)
                    }
                })
        },
        print() {
            const opt = {
                margin: 0.1,
                filename: this.invoice.user.name + " Invoice.pdf",
                image: { type: 'png', quality: 1 },
                html2canvas: { scale: 1.2, useCORS: true },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
            }
            html2pdf().set(opt).from(document.getElementById("invoice")).save()
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
}
</script>

<style scoped lang="scss"></style>