<template>
    <v-row justify="center">
        <v-dialog :value="value" persistent max-width="800">
            <v-card>
                <v-card-title class="text-h5">
                    Invoice Builder
                </v-card-title>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="6">
                            <v-img :src="api_url + 'frontend/images/' + fd_data.company_logo"></v-img>
                        </v-col>
                        <v-col cols="6">
                            {{ fd_data.address }}
                            <br>
                            Tel: {{ fd_data.mobile }}
                        </v-col>
                        <v-col cols="12">
                            <p>Name of Deceased: {{ client.get_user_info.name }}</p>
                            <p>Account To: {{ client.get_user_info.representative ?? 'Reps of ' + client.get_user_info.name }}</p>
                            <p>Address: {{ client.get_user_info.address }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <b>Funeral Director Services</b>
                        </v-col>
                        <v-col cols="4">
                            <b>Amount</b>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in invoice.funeral_director_payments"
                        :key="'funeral_director_payments' + index">
                        <v-col cols="1">
                            <v-btn @click="invoice.funeral_director_payments.splice(index, 1)" icon>
                                <v-icon color="red">mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="7">
                            <v-text-field v-model="item.name" dense placeholder="Description"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="item.amount" dense placeholder="Amount" type="number">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <v-btn @click="addService()" dark>Add</v-btn>
                        </v-col>
                        <v-col cols="4" v-if="invoice.funeral_director_payments.length > 0">
                            Subtotal: €{{ subtotal1 }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <b>Payments Made on Your Behalf</b>
                        </v-col>
                        <v-col cols="4">
                            <b>Amount</b>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in invoice.payments_made" :key="'payments_made' + index">
                        <v-col cols="1">
                            <v-btn @click="invoice.payments_made.splice(index, 1)" icon>
                                <v-icon color="red">mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="7">
                            <v-text-field v-model="item.name" dense placeholder="Description"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="item.amount" dense placeholder="Amount" type="number">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <v-btn @click="addPayment()" dark>Add</v-btn>
                        </v-col>
                        <v-col cols="4" v-if="invoice.payments_made.length > 0">
                            Subtotal: €{{ subtotal2 }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                        </v-col>
                        <v-col cols="4">
                            Total: €{{ total }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            Professional Service Fee Includes:
                            <p>Advising on arrangements, liaising with embalmers, clergy, local media and grave diggers
                            </p>
                            <p>24 hr Reception/phone attendance and availability of funeral director.</p>
                            <p>Preparing payments for clergy, choir, gratuities and other related charges</p>
                            <p>Bank Details: {{fd_data.bank_details}}</p>
                            <p>Credit Card or Cheques made payable to {{ fd_data.company_name }}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="closeInvoiceBuilderDialog()">
                        Cancel
                    </v-btn>
                    <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="createInvoice()">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'value', 'client'
    ],
    data: () => ({
        api_url: null,
        invoice: {
            funeral_director_payments: [],
            payments_made: [],
        }
    }),
    mounted() {
        this.setData()
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        ...mapGetters({
            fd_data: 'auth/get_user'
        }),
        subtotal1() {
            let subtotal = 0
            this.invoice.funeral_director_payments.forEach(payment => {
                if (payment.amount > 0) {
                    subtotal += parseFloat(payment.amount)
                }
            })
            return subtotal
        },
        subtotal2() {
            let subtotal2 = 0
            this.invoice.payments_made.forEach(payment => {
                if (payment.amount > 0) {
                    subtotal2 += parseFloat(payment.amount)
                }
            })
            return subtotal2
        },
        total() {
            return this.subtotal1 + this.subtotal2
        },
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit('auth/setMessage',
                { show: true, message: message },
                { root: 1 })
        },
        setData() {
            if(this.client.invoice != null) {
                this.invoice.funeral_director_payments = JSON.parse(this.client.invoice.funeral_director_payments)
                this.invoice.payments_made = JSON.parse(this.client.invoice.payments_made)
            }
        },
        closeInvoiceBuilderDialog() {
            this.$emit('closeInvoiceBuilderDialog')
        },
        addService() {
            this.invoice.funeral_director_payments.push({ name: '', amount: null })
        },
        addPayment() {
            this.invoice.payments_made.push({ name: '', amount: null })
        },
        async createInvoice() {
            let payload = {
                ...this.invoice,
                total: this.total,
                client_id: this.client.client_id,
            }
            console.log(payload)
            await this.$axios.post('auth/fd_client/create_or_update_invoice', payload)
                .then(({data}) => {
                    console.log(data)
                    if(data.response) {
                        this.$emit('closeInvoiceBuilderDialog')
                        this.$store.dispatch('fd_client/get_clients')
                        this.$store.dispatch('fd_client/set_client', data.data)
                    }
                })
        }
    },
}
</script>

<style scoped lang="scss"></style>