import { render, staticRenderFns } from "./DialogAddClient.vue?vue&type=template&id=3195c730&scoped=true&"
import script from "./DialogAddClient.vue?vue&type=script&lang=js&"
export * from "./DialogAddClient.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3195c730",
  null
  
)

export default component.exports