<template>
    <v-container fluid>
        <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
        <v-row v-else justify="center" align="center">
            <v-card width="90%" class="mt-5" elevation="0">
                <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
                    <strong style="font-size:26px;">Email Management</strong>
                    <v-spacer />
                </v-card-title>
                <v-card-subtitle class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
                    Manage emails sent to clients.
                </v-card-subtitle>
            </v-card>
            <v-card v-if="sequence.name" width="90%" style="border-radius: 16px" class="mb-10" elevation="0">
                <v-card-title>
                    {{ sequence.name }}
                </v-card-title>
                <v-card-title class="pl-15">
                    <v-select :items="clients" multiple item-text="get_user_info.name" item-value="client_id"
                        v-model="sequence.client_ids" label="Select clients to add to sequence..."
                        style="max-width:50%!important;"></v-select>
                    <v-btn @click="addClientsToSequence()" color="primary" class="ml-5">Save</v-btn>
                </v-card-title>
                <v-card-title class="pl-15">
                    <v-btn @click="createFdEmailSequence()" color="primary" class="mx-10"
                        v-if="sequence.created_by != user.id">Customize Emails</v-btn>
                    <v-btn v-else @click="openEmail('new')" dark text color="#172C54"><v-icon
                            class="mr-2">mdi-plus</v-icon>
                        Add Email To Sequence</v-btn>
                </v-card-title>
                <v-card-text class="pl-15">
                    <v-data-table :items="sequence.get_emails" :headers="email_headers" hide-default-footer
                        disable-pagination>
                        <template v-slot:item="{ item }">
                            <tr>
                                <td align="center">
                                    <b>{{ (item.client_ids) ? item.client_ids.length : 0 }}</b>
                                    <v-btn v-if="item.client_ids && item.client_ids.length > 0" small text
                                        @click="openClientList(item)">View</v-btn>
                                </td>
                                <td>
                                    <small>
                                        <div class="mb-5"><b>Subject:</b> {{ item.subject }}</div>
                                        <div class="mb-5"><b>Time:</b> {{ item.time }}</div>
                                        <div class="mb-5">Sent after {{ item.interval }} day<span
                                                v-if="item.interval != 1">s</span> after user is created</div>
                                    </small>
                                </td>
                                <td>
                                    <div style="overflow-y:scroll;max-height:350px;padding:20px;" v-html="item.content">
                                    </div>
                                </td>
                                <td>
                                    <div v-if="sequence.created_by == user.id">
                                        <v-btn style="min-width:20px;" text color="#172C54"
                                            @click="openEmail('edit', item)"><v-icon>mdi-pen</v-icon></v-btn>
                                        <v-btn style="min-width:20px;" text color="red"
                                            @click="openDelete('email', item)"><v-icon>mdi-delete</v-icon></v-btn>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template slot="no-data">
                            No emails found in sequence
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="open_email" persistent max-width="600">
                <v-card>
                    <v-card-title v-if="email_type == 'new'">New Email</v-card-title>
                    <v-card-title v-if="email_type == 'edit'">Edit Email</v-card-title>
                    <v-card-text>
                        <v-dialog ref="dialog" v-model="open_time" :return-value.sync="email.time" persistent lazy
                            full-width width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="email.time" label="Time of day *" readonly
                                    v-on="on"></v-text-field>
                            </template>
                            <v-time-picker v-if="open_time" v-model="email.time" full-width>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="open_time = false">Cancel</v-btn>
                                <v-btn color="primary" @click="$refs.dialog.save(email.time)">OK</v-btn>
                            </v-time-picker>
                        </v-dialog>
                        <v-text-field type="number" label="Wait how many days *"
                            v-model="email.interval"></v-text-field>
                        Variables
                        <div class="mb-5 d-flex" style="flex-wrap:wrap;">
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.client_name"></v-text-field>
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.client_email"></v-text-field>
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.client_password"></v-text-field>
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.fd_name"></v-text-field>
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.fd_company"></v-text-field>
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.invoice_link"></v-text-field>
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.checklist_link"></v-text-field>
                        </div>
                        <v-text-field label="Subject *" v-model="email.subject"></v-text-field>
                        <wysiwyg v-model="email.content" />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeEmail()">
                            Cancel
                        </v-btn>
                        <v-btn v-if="email_type == 'new'" color="#172C54" dark class="pl-10 pr-10"
                            @click="createEmail()">
                            Create
                        </v-btn>
                        <v-btn v-if="email_type == 'edit'" color="#172C54" dark class="pl-10 pr-10"
                            @click="editEmail()">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="open_delete" persistent max-width="600">
                <v-card>
                    <v-card-title>Are you sure?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="open_delete = false">
                            Cancel
                        </v-btn>
                        <v-btn v-if="delete_type == 'email'" text color="red" dark class="pl-10 pr-10"
                            @click="deleteEmail()">
                            <v-icon class="mr-2">mdi-delete</v-icon> Delete Email
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="open_client_list" persistent max-width="600">
                <v-card v-if="selected_email">
                    <v-card-title>Clients Sent</v-card-title>
                    <v-card-text>
                        <v-data-table :items="get_clients_by_id" :headers="client_headers" hide-default-footer
                            disable-pagination>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.get_user_info.name }}</td>
                                    <td>{{ item.get_user_info.email }}</td>
                                    <td>{{ item.get_fd_info.company_name }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeClientList()">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [],
    data: () => ({
        api_url: null,
        loading: true,
        sequence: null,
        clients: [],
        email_headers: [
            { text: 'Clients Sent', sortable: false, width: '10%' },
            { text: "Details", sortable: false, width: "10%" },
            { text: 'Content', sortable: false, width: '60%' },
            { text: "", sortable: false, width: "20%" },
        ],
        client_headers: [
            { text: 'Name', sortable: false },
            { text: 'Email', sortable: false },
            { text: 'Funeral Director', sortable: false }
        ],
        open_time: false,
        open_email: false,
        open_delete: false,
        open_client_list: false,
        selected_email: null,
        delete_type: null,
        email_type: null,
        email_id: null,
        email: {
            sequence_id: null,
            subject: null,
            content: null,
            time: null,
            interval: null,
        },
        variables: {
            client_name: "{{client_name}}",
            client_email: "{{client_email}}",
            client_password: "{{client_password}}",
            fd_name: "{{fd_name}}",
            fd_company: "{{fd_company}}",
            invoice_link: "{{invoice_link}}",
            checklist_link: "{{checklist_link}}",
        },
    }),
    async mounted() {
        this.getActiveEmailSequence()
        this.getClients()
        await this.$store.dispatch('fds/get_whole_clients')
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        ...mapGetters({
            user: 'auth/get_user',
        }),
        get_clients_by_id() {
            return this.$store.getters['fds/get_clients_by_id'](this.selected_email.client_ids)
        },
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getActiveEmailSequence() {
            await this.$axios.get('auth/fd_emails/get_active_email_sequence')
                .then(({ data }) => {
                    console.log(data)
                    this.loading = false
                    if (data.response) {
                        this.sequence = data.data
                    }
                })
        },
        async getClients() {
            await this.$axios.get('auth/fd_client/get_clients')
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.clients = data.data
                    }
                })
        },
        async addClientsToSequence() {
            let payload = {
                name: this.sequence.name,
                client_ids: this.sequence.client_ids,
            }
            console.log(payload)
            await this.$axios.patch(`auth/fd_emails/edit_email_sequence/${this.sequence.id}`, payload)
                .then(({ data }) => {
                    console.log(data)
                })
        },
        async createFdEmailSequence() {
            let payload = {
                ...this.sequence,
                user_id: this.user.id,
            }
            console.log(payload)
            await this.$axios.post('auth/fd_emails/create_fd_email_sequence', payload)
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.getActiveEmailSequence()
                    }
                })
        },
        openEmail(type, item) {
            this.open_email = true
            this.email_type = type
            this.email.sequence_id = this.sequence.id
            if (type == 'edit') {
                this.email_id = item.id
                this.email.subject = item.subject
                this.email.content = item.content
                this.email.time = item.time
                this.email.interval = item.interval
            }
        },
        closeEmail() {
            this.open_email = false
            this.email_id = null
            this.email.sequence_id = null
            this.email.subject = null
            this.email.content = null
            this.email.time = null
            this.email.interval = null
        },
        openDelete(type, item) {
            this.delete_type = type
            this.open_delete = true
            if (type == 'email') {
                this.email_id = item.id
            }
        },
        closeDelete() {
            this.delete_type = null
            this.open_delete = false
        },
        async createEmail() {
            await this.$axios.post('auth/admin/emails/create_email', this.email)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeEmail()
                        this.getActiveEmailSequence()
                    }
                })
        },
        async editEmail() {
            await this.$axios.patch(`auth/admin/emails/edit_email/${this.email_id}`, this.email)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeEmail()
                        this.getActiveEmailSequence()
                    }
                })
        },
        async deleteEmail() {
            await this.$axios.delete(`auth/admin/emails/delete_email/${this.email_id}`)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeDelete()
                        this.getActiveEmailSequence()
                    }
                })
        },
        openClientList(item) {
            this.open_client_list = true
            this.selected_email = item
        },
        closeClientList() {
            this.open_client_list = false
            this.selected_email = null
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.placeholder {
    width: 30%;
    height: 20px;
    font-size: 12px;
    border-radius: 4px;
    padding-left: 10px;
}

.placeholder:hover {
    background-color: rgb(233, 233, 233);
}

::v-deep .placeholder input {
    cursor: pointer !important;
}

::v-deep .placeholder .v-input__slot,
::v-deep .placeholder .v-input__control {
    min-height: 10px !important;
    height: 20px !important;
}

::v-deep .placeholder .v-input__control>.v-input__slot:before,
::v-deep .placeholder .v-input__control>.v-input__slot:after {
    border-style: none !important;
}
</style>
