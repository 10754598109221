<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card width="90%" class="mt-5" style="background: none !important" elevation="0">
        <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
          <v-img :src="require('../../assets/fd_clients.svg')" class="mr-3" contain max-width="35" max-height="35" />
          <strong style="font-size:26px;">Clients</strong>
          <v-spacer />
          <!-- <v-btn
                        text
                    >
                        <v-icon>mdi-bell-outline</v-icon>
                    </v-btn> -->
        </v-card-title>
        <v-card-title v-if="get_client.get_user_info.representative == null" class="text-small pb-0"
          style="background-color: #f4f4f5 !important;height:70px">
          <v-btn @click="$router.push({ name: '/preplanning-clients' })"
            style="position: relative; top: -10px; font-size:14px;text-transform:none;" class="pl-0" color="#347CE9"
            text>
            <v-icon color="#347CE9">mdi-chevron-left</v-icon>
            <strong>Back to Preplanning Clients</strong>
          </v-btn>
        </v-card-title>
        <v-card-title v-else class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
          <v-btn @click="$router.push({ name: '/funeral-clients' })"
            style="position: relative; top: -10px; font-size:14px;text-transform:none;" class="pl-0" color="#347CE9"
            text>
            <v-icon color="#347CE9">mdi-chevron-left</v-icon>
            <strong>Back to Funeral Clients</strong>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-card width="90%" style="border-radius: 16px" elevation="0"
        :class="isMobile() ? 'mobile_card' : 'desktop_card'">
        <v-card-title>
          <v-list-item two-line class="pa-5">
            <v-avatar v-if="get_client.profile_picture === null" size="75" color="blue"
              style="position: relative; right: 18px;top:-20px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
              <strong style="color:white;font-size:22px;">{{ get_client.get_user_info.name[0] }}</strong>
            </v-avatar>
            <v-avatar v-else size="75" color="blue"
              style="position: relative; right: 18px;top:-20px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
              <v-img contain :src="`${api_url}${get_client.profile_picture}`" />
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 30px;color:#494949">
                <h4 style="word-break: break-word;font-size: 25px;white-space: pre-wrap;">{{
                  get_client.get_user_info.name
                  }}</h4>
                <small style="font-size:12px;" v-if="get_client.get_user_info.representative != null">Represented by
                  {{ get_client.get_user_info.representative }}</small>
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 12px;white-space:pre-line;word-break:break-word;" class="mt-2">
                Last Login:
                {{
                $date(get_client.get_user_info.updated_at).format("DD MMM YYYY")
                }}
                <strong style="font-size:16px;margin-left:1px;margin-right:1px;margin-top:-2px">|</strong>
                {{
                $date(get_client.get_user_info.updated_at).format("hh:mm a")
                }}
                <v-btn text style="text-transform: none;float:right;color:red" :class="isMobile() ? 'mt-2' : ''"
                  @click="dialog_delete_client = true;">
                  Delete
                  <v-icon style="font-size:20px;color:red" class="ml-1 mb-1">mdi-delete</v-icon>
                </v-btn>
                <v-btn text style="text-transform: none;float:right;color:#347CE9" :class="isMobile() ? 'mt-2' : ''"
                  @click="open_dialog_edit_client()">
                  Edit
                  <v-icon style="font-size:20px;color:#347CE9" class="ml-1 mb-1">mdi-pencil-outline</v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-title>
        <v-card-subtitle>
          <v-row>
            <v-col :cols="isMobile() ? 12 : 6">
              <v-row>
                <v-col cols="6"> Funeral Director </v-col>
                <v-col cols="6" class="float-right" style="text-align: right">
                  <strong style="color:#347CE9">{{ get_client.get_fd_info.name }}</strong>
                </v-col>
                <v-col cols="6"> Contact number </v-col>
                <v-col cols="6" class="float-right" style="text-align: right">
                  <strong v-if="get_client.get_user_info.mobile !== null">{{
                    get_client.get_user_info.mobile
                    }}</strong>
                  <strong v-else>N/A</strong>
                </v-col>
                <v-col cols="4"> Email </v-col>
                <v-col cols="8" class="float-right" style="text-align: right">
                  <strong v-if="get_client.get_user_info.email !== null">{{
                    get_client.get_user_info.email
                    }}</strong>
                  <strong v-else>N/A</strong>
                </v-col>
                <v-col cols="4"> Address </v-col>
                <v-col cols="8" class="float-right" style="text-align: right">
                  <strong>
                    {{ get_client.get_user_info.address }}
                    {{ get_client.get_user_info.county }}
                    {{ get_client.get_user_info.country }}
                    {{ get_client.get_user_info.eircode }}
                  </strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 6">
              <v-row>
                <v-col cols="4"> Age </v-col>
                <v-col cols="8" class="float-right" style="text-align: right">
                  <strong v-if="get_client.get_user_info.age !== null">{{
                    get_client.get_user_info.age
                    }}</strong>
                  <strong v-else>N/A</strong>
                </v-col>
                <v-col cols="4"> Gender </v-col>
                <v-col cols="8" class="float-right" style="text-align: right">
                  <strong v-if="get_gender !== null">{{
                    get_gender.name
                    }}</strong>
                  <strong v-else>N/A</strong>
                </v-col>
                <v-col cols="4"> Birthday </v-col>
                <v-col cols="8" class="float-right" style="text-align: right">
                  <strong v-if="get_client.get_user_info.birthday !== null">{{
                    $date(get_client.get_user_info.birthday).format(
                    "DD MMMM, YYYY"
                    )
                    }}</strong>
                  <strong v-else>N/A</strong>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-subtitle>
      </v-card>
    </v-row>
    <v-row justify="center" align="center">
      <v-card width="90%" :class="isMobile() ? 'mobile_card' : 'desktop_card'"
        style="border-radius: 16px;margin-top:20px;" elevation="0">
        <v-card-title>
          <v-tabs v-model="tab" background-color="transparent" color="basil" slider-color="#347CE9" grow>
            <v-tab
              v-for="(item, itemindex) in (user.feature[0] == 'BASIC' || user.feature == null) ? basic_items : advance_items"
              :key="itemindex" style="text-transform: none;" :href="`#tab-${itemindex}`">
              <strong>{{ item }}</strong>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-0">
              <v-card flat width="2000">
                <v-row>
                  <v-col cols="6">
                    <v-card-title class="my-10">
                      <label class="text-center" style="color:#347CE9;font-size:18px;">Funeral Cost</label>
                      <div v-if="!edit_funeral_cost" class="ml-15">
                        <small v-if="get_client.funeral_cost != null">€{{ get_client.funeral_cost }}</small>
                        <small v-else>N/A</small>
                      </div>
                      <v-spacer />
                      <small v-if="!user.get_fd_payment_gateway">Please add a Trust Payments site reference on your
                        dashboard</small>
                      <v-btn text v-if="!edit_funeral_cost && user.get_fd_payment_gateway"
                        style="text-transform: none !important; color: #347CE9 !important;"
                        @click="dialog_cost_estimate = true;">
                        Edit
                        <v-img width="25" height="25" :src="require('../../assets/edit.svg')" contain />
                      </v-btn>
                    </v-card-title>
                    <v-card-subtitle v-if="user.get_fd_payment_gateway && get_client.funeral_cost != null">
                      <v-btn dark color="#172C54" class="pl-2 pr-2 pt-2 pb-2 mt-1"
                        style="text-transform: none !important" @click="copy_funeral_payment_link()">
                        Copy Funeral Payment Link
                      </v-btn>
                    </v-card-subtitle>
                    <v-card-title>
                      Invoice
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="5">
                          <v-btn @click="openInvoiceBuilderDialog()" dark color="#172C54"
                            style="text-transform: none !important">
                            Invoice builder
                          </v-btn>
                        </v-col>
                        <v-col cols="5">
                          <v-btn v-if="get_client.invoice && get_client.invoice.funeral_director_payments"
                            @click="viewInvoice()" text color="#172C54" style="text-transform: none !important">View
                            built
                            invoice</v-btn>
                        </v-col>
                        <v-col cols="2" v-if="get_client.invoice && get_client.invoice.funeral_director_payments">
                          <v-btn v-if="get_client.invoice.use_uploaded == 1" @click="updateSelectedInvoice(0)" text>
                            Select
                          </v-btn>
                          <v-btn v-else disabled text>
                            Selected
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5">
                          <v-file-input v-model="invoice_file" @change="uploadInvoice()" outlined dense
                            placeholder="Upload PDF" style="width:160px!important;" prepend-icon=""></v-file-input>
                        </v-col>
                        <v-col cols="5">
                          <v-btn v-if="get_client.invoice && get_client.invoice.file"
                            :href="api_url + 'frontend/invoices/' + get_client.invoice.file" text color="#172C54"
                            style="text-transform: none !important">View PDF invoice</v-btn>
                        </v-col>
                        <v-col cols="2" v-if="get_client.invoice && get_client.invoice.file">
                          <v-btn v-if="get_client.invoice.use_uploaded == 0" @click="updateSelectedInvoice(1)" text>
                            Select
                          </v-btn>
                          <v-btn v-else disabled text>
                            Selected
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col cols="6" style="font-size:14px;" class="pt-10" v-if="get_client.get_client_payment_term">
                    <p style="text-transform:capitalize;">{{ get_client.get_client_payment_term.interval }}ly Amount
                      Chosen: €{{ get_client.get_client_payment_term.amount.toFixed(2) }}</p>
                    <p style="text-transform:capitalize;">
                      Total {{ get_client.get_client_payment_term.interval }}ly Payment (including Administration Fee):
                      €{{
                      (get_client.get_client_payment_term.amount +
                      get_client.get_client_payment_term.admin_fee).toFixed(2) }}
                    </p>
                    <p>
                      Duration: {{ get_client.get_client_payment_term.duration }} {{
                      get_client.get_client_payment_term.interval }}s
                    </p>
                    <form method="POST" action="https://payments.securetrading.net/process/payments/choice">
                      <input type="hidden" name="sitereference" :value="user.get_fd_payment_gateway.site_reference">
                      <input type="hidden" name="stprofile" value="default">
                      <input type="hidden" name="currencyiso3a" value="EUR">
                      <input type="hidden" name="mainamount" :value="get_client.get_client_payment_term.amount +
                        get_client.get_client_payment_term.admin_fee">
                      <input type="hidden" name="version" value="2">
                      <input type="hidden" name="billingemail" :value="get_client.get_user_info.email">
                      <input type="hidden" name="billingfirstname" :value="get_client.get_user_info.firstname">
                      <input type="hidden" name="billinglastname" :value="get_client.get_user_info.lastname">
                      <input type="hidden" name="billingtown" :value="get_client.get_user_info.town">
                      <input type="hidden" name="billingcounty" :value="get_client.get_user_info.county">
                      <input type="hidden" name="billingpostcode" :value="get_client.get_user_info.eircode">
                      <input type="hidden" name="ruleidentifier" value="STR-6">
                      <input type="hidden" name="successfulurlredirect"
                        :value="app_url + '/funeral-payment-successful' + get_client.encrypted_id">

                      <input v-if="get_client.get_client_payment_term.interval == 'month'" type="hidden"
                        name="subscriptionunit" value="MONTH">
                      <input v-if="get_client.get_client_payment_term.interval == 'week'" type="hidden"
                        name="subscriptionunit" value="DAY">

                      <input v-if="get_client.get_client_payment_term.interval == 'month'" type="hidden"
                        name="subscriptionfrequency" value="1">
                      <input v-if="get_client.get_client_payment_term.interval == 'week'" type="hidden"
                        name="subscriptionfrequency" value="7">

                      <input type="hidden" name="subscriptionnumber" value="1">
                      <input type="hidden" name="subscriptionfinalnumber"
                        :value="get_client.get_client_payment_term.duration">
                      <input type="hidden" name="subscriptiontype" value="INSTALLMENT">
                      <input type="hidden" name="credentialsonfile" value="1">

                      <v-btn dark color="#172C54" type="submit" class="mt-5">Process Payment Now</v-btn>
                    </form>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-1">
              <v-card flat width="2000">
                <v-card-text v-if="client_transactions != null">
                  <v-row>
                    <v-col cols="6">
                      <v-btn @click="refreshTransactions(true)" text>Refresh</v-btn>
                      <vue-excel-xlsx :data="client_transactions.filter((x) => x.settlestatus == 100)"
                        :columns="columns"
                        :file-name="get_client.get_user_info.firstname + ' ' + get_client.get_user_info.lastname + ' transactions'"
                        :file-type="'xlsx'" :sheet-name="'Sheet 1'">
                        <v-btn text>Export</v-btn>
                      </vue-excel-xlsx>
                    </v-col>
                    <v-col cols="6">
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table :items="client_transactions.filter((x) => x.settlestatus == 100)"
                        :headers="transaction_headers" :sort-by.sync="transaction_sort_by"
                        :sort-desc.sync="transaction_sortDesc">
                        <template v-slot:item="{ item }">
                          <tr class="mx-5" style="background-color: transparent;">
                            <td>
                              {{ item.settleduedate }}
                            </td>
                            <td>
                              <div v-if="item.requesttypedescription == 'AUTH'">
                                Deposit
                              </div>
                              <div v-else>
                                Subscription
                              </div>
                            </td>
                            <td>
                              {{ item.baseamountactual }}
                            </td>
                            <td>
                              {{ item.settlebaseamountactual }}
                            </td>
                            <td>
                              {{ item.currencyiso3a }}
                            </td>
                            <td>
                              {{ item.paymenttypedescription }}
                            </td>
                            <td>
                              {{ item.transactionreference }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-card flat width="2000">
                <v-card-text>
                  <v-simple-table class="mt-5">
                    <template v-slot:default>
                      <thead v-if="!isMobile()">
                        <tr>
                          <th class="text-center" style="color:#347CE9;font-size:18px;">Status</th>
                          <th class="text-center" style="color:#347CE9;font-size:18px;">Schedule</th>
                          <th class="text-center" style="color:#347CE9;font-size:18px;">Action</th>
                          <th class="text-right">
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="background-color: transparent;" v-if="!isMobile()">
                          <td class="text-center" style="width:25%">
                            <v-layout justify-center align-center class="ms-5">
                              <v-select chips :items="statuses" v-model="get_client.get_status" item-text="name"
                                item-value="id" @change="change_client_status(get_client.id, get_client.get_status)">
                                <template v-slot:append>
                                  <v-icon color="blue" class="mt-1" style="margin-left:-15px;">mdi-chevron-down</v-icon>
                                </template>
                                <template #selection="{ item }">
                                  <v-chip style="color:white;width:90%;cursor: pointer;" :class="(item.id == 1) ? 'status_1' :
                                    (item.id == 2) ? 'status_2' :
                                      (item.id == 3) ? 'status_3' :
                                        (item.id == 4) ? 'status_4' :
                                          (item.id == 5) ? 'status_5' : 'status_0'">
                                    <strong style="font-size:12px;">{{ item.name }}</strong>
                                  </v-chip>
                                </template>
                              </v-select>
                            </v-layout>
                          </td>
                          <td class="text-center" style="width:25%">
                            <div v-if="get_client.schedule != null">
                              {{
                              $date(get_client.schedule).format("DD MMM YYYY")
                              }}
                              <strong
                                style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                              {{
                              $date(get_client.schedule).format("hh:mm a")
                              }}
                            </div>
                            <div v-else>Not set</div>
                          </td>
                          <td class="text-center" style="width:25%">
                            <div v-if="get_client.action != null">
                              {{ get_client.action }}
                            </div>
                            <div v-else>
                              Not set
                            </div>
                          </td>
                          <td class="text-right" style="width:25%">
                            <v-btn text style="text-transform: none;float:right;color:#347CE9"
                              @click="open_dialog_edit_status(get_client)">
                              Edit
                              <v-icon style="font-size:20px;color:#347CE9" class="ml-1 mb-1">mdi-pencil-outline</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                        <tr style="background-color: transparent;" v-else>
                          <td class="text-center">
                            <v-row>
                              <v-col cols="4" style="height:auto;" class="d-flex">
                                <v-layout align-center>
                                  <h4>Status</h4>
                                </v-layout>
                              </v-col>
                              <v-col cols="8">
                                <v-layout justify-center align-center class="ms-5">
                                  <v-select chips :items="statuses" v-model="get_client.get_status" item-text="name"
                                    item-value="id"
                                    @change="change_client_status(get_client.id, get_client.get_status)">
                                    <template v-slot:append>
                                      <v-icon color="blue" class="mt-1"
                                        style="margin-left:-15px;">mdi-chevron-down</v-icon>
                                    </template>
                                    <template #selection="{ item }">
                                      <v-chip style="color:white;width:90%;cursor: pointer;" :class="(item.id == 1) ? 'status_1' :
                                        (item.id == 2) ? 'status_2' :
                                          (item.id == 3) ? 'status_3' :
                                            (item.id == 4) ? 'status_4' :
                                              (item.id == 5) ? 'status_5' : 'status_0'">
                                        <strong style="font-size:12px;">{{ item.name }}</strong>
                                      </v-chip>
                                    </template>
                                  </v-select>
                                </v-layout>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="4" style="height:auto;" class="d-flex">
                                <v-layout align-center>
                                  <h4>Schedule</h4>
                                </v-layout>
                              </v-col>
                              <v-col cols="8">
                                <div v-if="get_client.schedule != null">
                                  {{
                                  $date(get_client.schedule).format("DD MMM YYYY")
                                  }}
                                  <strong
                                    style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                                  {{
                                  $date(get_client.schedule).format("hh:mm a")
                                  }}
                                </div>
                                <div v-else>Not set</div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="4" style="height:auto;" class="d-flex">
                                <v-layout align-center>
                                  <h4>Action</h4>
                                </v-layout>
                              </v-col>
                              <v-col cols="8">
                                <div v-if="get_client.action != null">
                                  {{ get_client.action }}
                                </div>
                                <div v-else>
                                  Not set
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <v-btn text style="text-transform: none;float:left;color:#347CE9;margin-left:-15px;"
                                  @click="open_dialog_edit_status(get_client)">
                                  Edit
                                  <v-icon style="font-size:20px;color:#347CE9"
                                    class="ml-1 mb-1">mdi-pencil-outline</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <v-card flat width="2000" class="mt-5">
                <v-card-title>
                  <label class="text-center" style="color:#347CE9;font-size:18px;">Notes</label>
                  <v-spacer />
                  <v-btn text v-if="!edit_notes" style="text-transform: none !important; color: #347CE9 !important;"
                    @click="open_notes()">
                    Edit
                    <v-img width="25" height="25" :src="require('../../assets/edit.svg')" contain />
                  </v-btn>
                </v-card-title>
                <v-card-subtitle>
                  <div v-if="!edit_notes">
                    <div v-if="get_client.notes != null">{{ get_client.notes }}</div>
                    <div v-else>N/A</div>
                  </div>
                  <v-text-field v-else filled dense placeholder="Add notes here..." v-model="notes_to_edit"
                    v-on:keyup.enter="save_notes()">
                    <template v-slot:append>
                      <v-btn class="mb-2" text @click="save_notes()">Save</v-btn>
                    </template>
                  </v-text-field>
                </v-card-subtitle>
                <v-card-title>
                  <label class="text-center" style="color:#347CE9;font-size:18px;">Documents</label>
                  <v-spacer />
                  <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                    style="height:40px;text-transform: none !important" @click="dialog_add_document = true">
                    Add document <v-icon class="ml-2">mdi-plus</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text><v-row>
                    <v-col cols="12">
                      <v-data-table :sort-by.sync="sort_by" :sort-desc.sync="sortDesc" :headers="document_headers"
                        hide-default-header :items="get_client.get_files" :items-per-page="10">
                        <template v-slot:header="{ props: { headers } }">
                          <thead>
                            <tr v-if="!isMobile()">
                              <th style="padding-bottom:30px;padding-top:10px;width:25%;" v-for="h in headers"
                                :key="h.index">
                                <div style="text-align:center;" v-if="h.text">
                                  <strong>{{ h.text }}</strong>
                                </div>
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:item="{ item }">
                          <tr v-if="!isMobile()" class="mx-5" style="background-color: transparent;">
                            <td>
                              <v-layout justify-center>
                                <v-icon v-if="item.extension == 'doc' || item.extension == 'docx' ||
                                  item.extension == 'pdf' || item.extension == 'epub'" size="75px"
                                  color="#172C54">mdi-file</v-icon>
                                <v-icon v-if="item.extension == 'jpg' || item.extension == 'jpeg' ||
                                  item.extension == 'png' || item.extension == 'svg'" size="75px"
                                  color="#172C54">mdi-file-image</v-icon>
                              </v-layout>
                              <h3 style="text-align:center;">{{ item.extension }}</h3>
                            </td>
                            <td>
                              <v-layout style="white-space:pre-line;word-break: break-word;">
                                {{ item.original_name }}
                              </v-layout>
                            </td>
                            <td style="width:50%;">
                              <v-layout v-if="item.description" class="py-5"
                                style="white-space:pre-line;word-break: break-word;">
                                {{ item.description }}
                              </v-layout>
                              <v-layout v-else justify-center>
                                N/A
                              </v-layout>
                            </td>
                            <td style="width:15%;">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn style="float:right;" text color="#347CE9" v-bind="attrs" v-on="on">
                                    Options
                                  </v-btn>
                                </template>
                                <v-list style="padding-top:0px;padding-bottom:0px;width:150px;">
                                  <v-list-item style="cursor: pointer" @click="edit_document_dialog(item)">
                                    <span style="text-transform: none !important;float:right;color:#347CE9">
                                      Edit
                                    </span>
                                  </v-list-item>
                                  <v-list-item style="cursor: pointer" @click="view_document(item)">
                                    <span style="text-transform: none !important;float:right;color:#347CE9">
                                      View
                                    </span>
                                  </v-list-item>
                                  <v-list-item style="cursor: pointer" @click="delete_document(item)">
                                    <span style="text-transform: none !important;float:right;color:red;">
                                      Delete
                                    </span>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td>
                          </tr>
                          <tr v-else class="mx-5" style="background-color: transparent;width:100%;">
                            <td class="py-5" style="width:500px;">
                              <v-row>
                                <v-col cols="12">
                                  <v-layout justify-center>
                                    <v-icon v-if="item.extension == 'doc' || item.extension == 'docx' ||
                                      item.extension == 'pdf' || item.extension == 'epub'" size="75px"
                                      color="#172C54">mdi-file</v-icon>
                                    <v-icon v-if="item.extension == 'jpg' || item.extension == 'jpeg' ||
                                      item.extension == 'png' || item.extension == 'svg'" size="75px"
                                      color="#172C54">mdi-file-image</v-icon>
                                  </v-layout>
                                  <h3 style="text-align:center;">{{ item.extension }}</h3>
                                </v-col>
                                <v-col cols="12" style="white-space:pre-line;word-break: break-word;">
                                  <b>{{ document_headers[1].text }}</b>
                                  <br>{{ item.original_name }}
                                </v-col>
                                <v-col cols="12">
                                  <label style="white-space:pre-line;word-break: break-word;">
                                  </label>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" style="white-space:pre-line;word-break: break-word;">
                                  <b>{{ document_headers[2].text }}</b>
                                  <br>
                                  <label v-if="item.description" class="py-5"
                                    style="white-space:pre-line;word-break: break-word;">
                                    {{ item.description }}
                                  </label>
                                  <label v-else justify-center>
                                    N/A
                                  </label>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12">
                                  <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn style="float:right;" text color="#347CE9" v-bind="attrs" v-on="on">
                                        Options
                                      </v-btn>
                                    </template>
                                    <v-list style="padding-top:0px;padding-bottom:0px;width:150px;">
                                      <v-list-item style="cursor: pointer" @click="edit_document_dialog(item)">
                                        <span style="text-transform: none !important;float:right;color:#347CE9">
                                          Edit
                                        </span>
                                      </v-list-item>
                                      <v-list-item style="cursor: pointer" @click="view_document(item)">
                                        <span style="text-transform: none !important;float:right;color:#347CE9">
                                          View
                                        </span>
                                      </v-list-item>
                                      <v-list-item style="cursor: pointer" @click="delete_document(item)">
                                        <span style="text-transform: none !important;float:right;color:red;">
                                          Delete
                                        </span>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
                <!-- <v-card-title>
                  <label class="text-center" style="color:#347CE9;font-size:18px;">Tags</label>
                  <v-spacer />
                  <v-btn text style="text-transform: none !important; color: #347CE9 !important;"
                    @click="open_tags_dialog()">
                    Edit
                    <v-img width="25" height="25" :src="require('../../assets/edit.svg')" contain />
                  </v-btn>
                </v-card-title>
                <v-card-subtitle>
                  <v-chip v-for="(chip, chipitem) in get_client.tags" :key="chipitem" color="#172C54"
                    style="width: fit; justify-content: center; color: white;" class="pl-5 pr-5 mr-2">
                    {{ chip }}
                  </v-chip>
                </v-card-subtitle> -->
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-title>
      </v-card>
    </v-row>

    <v-row justify="center" align="center">
      <v-card width="90%" class="mt-5" style="border-radius: 16px" elevation="0">
        <v-card elevation="0" :class="isMobile() ? 'mobile_card' : 'desktop_card'">
          <v-card-title v-if="get_client.get_user_info.representative == null">
            <v-img :src="require('../../assets/activity.svg')" max-width="50" max-height="50" contain />
            <h2 class="ml-4">Activity</h2>
          </v-card-title>
          <v-card-title v-if="get_client.get_user_info.representative == null">
            <v-tabs v-model="tabactivity" background-color="transparent" color="basil" grow
              style="display:flex;justify-content:center;width:200%">
              <v-tab v-for="(item, itemindex) in items_activity" :key="itemindex"
                style="text-transform: none !important;width:120px;" slider-color="#347CE9" :href="`#tab-${itemindex}`">
                <p style="white-space:pre-line;word-break:break-word;">{{ item }}</p>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabactivity">
              <v-tab-item value="tab-0">
                <FarewellWishes />
              </v-tab-item>
              <v-tab-item value="tab-1">
                <small style="white-space:pre-line;word-break:break-word;">Client shared their profile with
                  {{ get_profile_sharing.length }} people</small>
                <v-row v-if="get_profile_sharing.length > 0" class="pa-5">
                  <v-col :cols="isMobile() ? 12 : 12" v-for="(user, userindex) in get_profile_sharing" :key="userindex">
                    <v-list-item two-line>
                      <v-avatar v-if="user.get_client_info !== null && user.get_client_info.profile_picture !== null"
                        size="55" color="blue"
                        style="position: relative; right: 18px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                        <v-img contain :src="`${api_url}${user.get_client_info.profile_picture}`" />
                      </v-avatar>
                      <v-avatar v-else size="55" color="blue"
                        style="position: relative; right: 18px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                        <strong style="font-size: 20px;color:white;">{{ user.email[0] }}</strong>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 16" class="ml-3">
                          <strong>{{ user.email }}</strong>
                        </v-list-item-title>
                        <v-list-item-subtitle class="ml-3">
                          <label v-if="user.client_id !== null">
                            Has profile
                          </label>
                          <label v-else> Has no profile yet </label>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-row>
                  <v-col cols="12">
                    <v-data-table :sort-by.sync="sort_by" :sort-desc.sync="sortDesc" :headers="document_headers"
                      hide-default-header :items="get_client.get_files" :items-per-page="10">
                      <template v-slot:header="{ props: { headers } }">
                        <thead>
                          <tr v-if="!isMobile()">
                            <th style="padding-bottom:30px;padding-top:10px;width:25%;" v-for="h in headers"
                              :key="h.index">
                              <div style="text-align:center;" v-if="h.text">
                                <strong>{{ h.text }}</strong>
                              </div>
                            </th>
                          </tr>
                        </thead>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr v-if="!isMobile()" class="mx-5" style="background-color: transparent;">
                          <td>
                            <v-layout justify-center>
                              <v-icon v-if="item.extension == 'doc' || item.extension == 'docx' ||
                                item.extension == 'pdf' || item.extension == 'epub'" size="75px"
                                color="#172C54">mdi-file</v-icon>
                              <v-icon v-if="item.extension == 'jpg' || item.extension == 'jpeg' ||
                                item.extension == 'png' || item.extension == 'svg'" size="75px"
                                color="#172C54">mdi-file-image</v-icon>
                            </v-layout>
                            <h3 style="text-align:center;">{{ item.extension }}</h3>
                          </td>
                          <td>
                            <v-layout style="white-space:pre-line;word-break: break-word;">
                              {{ item.original_name }}
                            </v-layout>
                          </td>
                          <td style="width:50%;">
                            <v-layout v-if="item.description" class="py-5"
                              style="white-space:pre-line;word-break: break-word;">
                              {{ item.description }}
                            </v-layout>
                            <v-layout v-else justify-center>
                              N/A
                            </v-layout>
                          </td>
                          <td style="width:15%;">
                            <v-btn text style="cursor: pointer" @click="view_document(item)">
                              <span style="text-transform: none !important;float:right;color:#347CE9">
                                View
                              </span>
                            </v-btn>
                          </td>
                        </tr>
                        <tr v-else class="mx-5" style="background-color: transparent;width:100%;">
                          <td class="py-5" style="width:500px;">
                            <v-row>
                              <v-col cols="12">
                                <v-layout justify-center>
                                  <v-icon v-if="item.extension == 'doc' || item.extension == 'docx' ||
                                    item.extension == 'pdf' || item.extension == 'epub'" size="75px"
                                    color="#172C54">mdi-file</v-icon>
                                  <v-icon v-if="item.extension == 'jpg' || item.extension == 'jpeg' ||
                                    item.extension == 'png' || item.extension == 'svg'" size="75px"
                                    color="#172C54">mdi-file-image</v-icon>
                                </v-layout>
                                <h3 style="text-align:center;">{{ item.extension }}</h3>
                              </v-col>
                              <v-col cols="12" style="white-space:pre-line;word-break: break-word;">
                                <b>{{ document_headers[1].text }}</b>
                                <br>{{ item.original_name }}
                              </v-col>
                              <v-col cols="12">
                                <label style="white-space:pre-line;word-break: break-word;">
                                </label>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" style="white-space:pre-line;word-break: break-word;">
                                <b>{{ document_headers[2].text }}</b>
                                <br>
                                <label v-if="item.description" class="py-5"
                                  style="white-space:pre-line;word-break: break-word;">
                                  {{ item.description }}
                                </label>
                                <label v-else justify-center>
                                  N/A
                                </label>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <v-btn text style="cursor: pointer" @click="view_document(item)">
                                  <span style="text-transform: none !important;float:right;color:#347CE9">
                                    View
                                  </span>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card-title>
          <v-card-title v-if="get_client.get_user_info.representative != null">
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <h2 style="white-space:pre-line;word-break:break-word;">Funeral Notice</h2>
                      </v-list-item-title>
                      <v-list-item-subtitle style="white-space:pre-line;word-break:break-word;" class="mt-2">

                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <v-row class="mt-5" v-if="get_client.get_funeral_notice != null">
                      <v-col cols="6">
                        <small style="font-size:12px;">Submitted: {{
                          $date(get_client.get_funeral_notice.created_at).format("MMM DD YYYY, hh:mm a")
                          }}</small>
                        <br>
                        <small style="font-size:12px;"
                          v-if="get_client.get_funeral_notice.updated_at != get_client.get_funeral_notice.created_at">Last
                          updated: {{ $date(get_client.get_funeral_notice.updated_at).format("MMM DD YYYY, hh:mm a")
                          }}</small>
                      </v-col>
                      <v-col cols="6">
                        <v-btn text color="#347CE9" style="float:right;"
                          @click="open_edit_funeral_notice()">Edit</v-btn>
                        <v-btn dark color="#347CE9" style="float:right;" class="mr-2"
                          @click="view_funeral_notice()">View</v-btn>
                        <v-btn dark color="#347CE9" style="float:right;" class="mr-2"
                          @click="copy_funeral_notice_link()">Copy
                          link</v-btn>
                      </v-col>
                      <v-col :cols="isMobile() ? 12 : 3">
                        <a :href="`${api_url}${get_client.get_user_info.funeral_notice_image}`" target="_blank">
                          <v-img v-if="get_client.get_user_info.funeral_notice_image != null" contain max-height="300px"
                            :src="`${api_url}${get_client.get_user_info.funeral_notice_image}`" />
                        </a>
                      </v-col>
                      <v-col :cols="isMobile() ? 12 : 9"
                        style="font-size:14px;white-space:pre-line;word-break:break-word;">
                        {{ get_client.get_funeral_notice.notice }}
                      </v-col>
                      <v-col cols="12" v-if="get_client.get_user_info.data">
                        Public arrangement answers
                        <br><br>
                        Public Visitation / Viewing / Reposing / Wake
                        <br>
                        <div class="pl-5" style="font-size:12px;">
                          <label v-if="get_client.get_user_info.data">
                            Type: {{ get_client.get_user_info.data.wake_type }}<br>
                            Time: {{ get_client.get_user_info.data.wake_time }}<br>
                            Location: {{ get_client.get_user_info.data.wake_location }}<br>
                            Specific location: {{ get_client.get_user_info.data.wake_specific_location }}
                          </label>
                        </div>
                        <br>
                        Ceremony
                        <br>
                        <div class="pl-5" style="font-size:12px;">
                          <label v-if="get_client.get_user_info.data">
                            Type: {{ get_client.get_user_info.data.ceremony_type }}<br>
                            Details: {{ get_client.get_user_info.data.ceremony_date }}<br>
                            Details: {{ get_client.get_user_info.data.ceremony_time }}<br>
                            Details: {{ get_client.get_user_info.data.ceremony_location }}
                          </label>
                        </div>
                        <br>
                        Burial / Cremation
                        <br>
                        <div class="pl-5" style="font-size:12px;">
                          <label v-if="get_client.get_user_info.data">
                            Type: {{ get_client.get_user_info.data.funeral_type }}<br>
                            Location: {{ get_client.get_user_info.data.funeral_location }}<br>
                            Private: {{ get_client.get_user_info.data.funeral_private }}
                          </label>
                        </div>
                        <br>
                        Other Funeral Notice Text Options
                        <br>
                        <div class="pl-5" style="font-size:12px;">
                          <label v-if="get_client.get_user_info.data">
                            Family Flowers Only: {{ get_client.get_user_info.data.other_family_flowers
                            }}<br>
                            Donations to a charity: {{ get_client.get_user_info.data.other_charity_donations
                            }}<br>
                            Live Funeral Streaming: {{ get_client.get_user_info.data.other_live_streaming
                            }}<br>
                            Optional text: {{ get_client.get_user_info.data.other_message }}
                          </label>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-else class="mt-5">
                      <v-col cols="12">
                        No Funeral Notice yet
                      </v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-title>
        </v-card>
      </v-card>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialog_edit_funeral_notice" persistent max-width="600">
        <v-card>
          <v-card-title class="text-h5">
            Edit funeral notice
          </v-card-title>
          <v-card-text class="mt-5">
            <v-textarea rows="10" filled v-model="funeral_notice" dense>
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog_edit_funeral_notice = false">
              Cancel
            </v-btn>
            <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="edit_funeral_notice()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog_edit_tags" persistent max-width="560">
        <v-card>
          <v-card-title>
            <small style="color:#347CE9;font-size:18px;">Edit Tags</small>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-combobox v-model="tags_to_edit" filled multiple chips dense hide-selected :items="get_client.tags">
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip v-bind="attrs" :input-value="selected" small color="#172C54" style="color:white;">
                      <span class="pr-2">
                        {{ item }}
                      </span>
                      <v-icon color="white" small @click="parent.selectItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Press <kbd>enter</kbd> to create a new tag
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="close_tags_dialog()">
              Cancel
            </v-btn>
            <v-btn color="#172C54" dark class="pl-10 pr-10" @click="save_tags()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog_edit_status" persistent max-width="560">
        <v-card>
          <v-card-title>
            <small>Edit schedule</small>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-5">
              <v-col cols="6">
                <strong>Schedule</strong>
                <v-dialog max-width="300px" persistent v-model="schedule_modal">
                  <template v-slot:activator="{ on: { click } }">
                    <v-text-field dense filled v-on:click="click"
                      v-model="to_edit_status.schedule_am_pm"></v-text-field>
                  </template>
                  <v-card>
                    <DatePicker mode="dateTime" is-expanded v-model="to_edit_status.schedule" scrollable actions />
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="schedule_modal = false">Cancel</v-btn>
                      <v-btn color="primary" @click="close_schedule_modal()">OK</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="6">
                <strong>Action</strong>
                <v-text-field dense filled v-model="to_edit_status.action"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="cancel_dialog_edit_status()">
              Cancel
            </v-btn>
            <v-btn color="#172C54" dark class="pl-10 pr-10" @click="edit_schedule()">
              Edit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog_delete_client" persistent max-width="560">
        <v-card>
          <v-card-title>
            <small>Delete Client</small>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog_delete_client = false;">
              Cancel
            </v-btn>
            <v-btn color="#172C54" dark class="pl-10 pr-10" @click="deleteClient()">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>


    <v-row justify="center">
      <v-dialog v-model="dialog_add_document" persistent max-width="560">
        <v-card>
          <v-card-title class="text-h5">
            Add documents and photos
          </v-card-title>
          <v-card-text class="mt-5">
            <v-file-input filled v-model="document_to_upload" dense label="Upload">
            </v-file-input>
            <v-textarea filled v-model="document_description" dense label="Description"></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog_add_document = false">
              Cancel
            </v-btn>
            <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="upload_document()">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add document
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialog_edit_document" persistent max-width="560">
        <v-card v-if="to_edit != null">
          <v-card-title class="text-h5">
            Edit document
          </v-card-title>
          <v-card-text class="mt-5">
            <v-text-field v-model="to_edit.original_name" dense filled placeholder="Document Name"></v-text-field>
            <v-textarea v-model="to_edit.description" dense filled placeholder="Description"></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="cancel_edit_document_dialog">
              Cancel
            </v-btn>
            <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="edit_document">
              <v-icon class="mr-2">mdi-pen</v-icon>
              Edit document
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <DialogEditClient :value="dialog_edit_client" :client="get_client.get_user_info"
      @cancel_dialog_edit_client="cancel_dialog_edit_client" />
    <DialogCostEstimate :value="dialog_cost_estimate" :client="get_client"
      @cancel_dialog_cost_estimate="cancel_dialog_cost_estimate" />
    <InvoiceBuilderDialog :value="invoice_builder_dialog" :client="get_client"
      @closeInvoiceBuilderDialog="closeInvoiceBuilderDialog" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
//import Will from './modules/WillComponent.vue'
import { DatePicker } from 'v-calendar'
import FarewellWishes from './modules/FarewellWishes.vue'
import DialogEditClient from './components/DialogEditClient.vue'
import DialogCostEstimate from './components/DialogCostEstimate.vue'
import InvoiceBuilderDialog from './components/InvoiceBuilderDialog.vue'
export default {
  components: {
    //Will,
    FarewellWishes, DialogEditClient, DatePicker, DialogCostEstimate, InvoiceBuilderDialog
  },
  props: [],
  data: () => ({
    tab: null,
    dialog_delete_client: false,
    dialog_edit_client: false,
    dialog_edit_status: false,
    dialog_cost_estimate: false,
    basic_items: [
      "Funeral Cost",
      "Payment History",
    ],
    advance_items: [
      "Funeral Cost",
      "Payment History",
      "Status Details",
      "Documents and Notes",
    ],
    tabactivity: null,
    items_activity: [
      "Farewell Wishes",
      "Profile Sharing",
      'Documents'
    ],
    statuses: [
      {
        name: 'BOOKED APPT.',
        id: 1
      },
      {
        name: 'CALLED',
        id: 2
      },
      {
        name: 'FOLLOW-UP',
        id: 3
      },
      {
        name: 'NEW LEAD',
        id: 4
      },
      {
        name: 'PLAN SOLD',
        id: 5
      }
    ],
    document_headers: [
      { text: '', sortable: false },
      { text: "Title", sortable: false },
      { text: "Description", sortable: false },
      { text: '', sortable: false },
    ],
    sort_by: 'created_at',
    sortDesc: true,
    api_url: null,
    app_url: null,
    schedule_modal: false,
    dialog_edit_tags: false,
    edit_notes: false,
    notes_to_edit: null,
    edit_funeral_cost: false,
    funeral_cost_to_edit: null,
    admin_charge: 0.06,
    deposit: 500,
    remainder: null,
    payment_breakdown: [],
    payment_term_breakdown: [],
    preview_duration: null,
    preview_amount: null,
    get_client_tags: [],
    tags_to_edit: [],
    to_edit_status: {
      id: null,
      schedule: null,
      schedule_am_pm: null,
      action: null,
    },
    dialog_edit_funeral_notice: false,
    funeral_notice: null,
    client_transactions: [],
    transaction_headers: [
      { text: 'Settle Due Date', value: 'settleduedate' },
      { text: 'Payment Type', value: 'requesttypedescription' },
      { text: 'Base Amount', value: 'baseamountactual' },
      { text: 'Settle Amount', value: 'settlebaseamountactual' },
      { text: 'Currency', value: 'currencyiso3a' },
      { text: 'Card Type', value: 'paymenttypedescription' },
      { text: 'Transaction Ref', value: '"transactionreference"' },
    ],
    transaction_sort_by: 'settleduedate',
    transaction_sortDesc: true,
    columns: [
      {
        label: "Settle Due Date",
        field: "settleduedate",
      },
      {
        label: "First Name",
        field: "billingfirstname",
      },
      {
        label: "Last Name",
        field: "billinglastname",
      },
      {
        label: "Payment Type",
        field: "requesttypedescription",
      },
      {
        label: "Base Amount",
        field: "baseamountactual",
      },
      {
        label: "Settle Amount",
        field: "settlebaseamountactual",
      },
      {
        label: "Currency",
        field: "currencyiso3a",
      },
      {
        label: "Card Type",
        field: "paymenttypedescription",
      },
      {
        label: "Transaction Ref",
        field: "transactionreference",
      }
    ],
    invoice_file: null,
    invoice_builder_dialog: false,
    dialog_add_document: false,
    document_to_upload: null,
    document_description: '',
    dialog_edit_document: false,
    to_edit: {
      id: null,
      original_name: null,
      description: null
    },
  }),
  async mounted() {
    await this.$store.dispatch('fd_client/get_gender', this.get_client.get_user_info.gender_id)
    await this.$store.dispatch('fd_client/fetch_profile_sharing', this.get_client.get_user_info.id)
    this.calculate_cost()
    console.log(this.get_client)
    this.refreshTransactions(false)
  },
  created() {
    this.api_url = process.env.VUE_APP_API_URL
    this.app_url = process.env.VUE_APP_BUILD
  },
  computed: {
    ...mapGetters({
      user: 'auth/get_user',
      get_client: 'fd_client/get_client',
      get_gender: 'fd_client/get_gender',
      get_profile_sharing: 'fd_client/get_profile_sharing',
      get_templates: 'fd_funeral_notice/get_templates',
    }),
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit('auth/setMessage',
        { show: true, message: message },
        { root: 1 })
    },
    async refreshClient() {
      console.log(this.get_client.client_id)
      await this.$axios.get(`auth/fd_client/get_client/${this.get_client.id}`)
        .then(({data}) => {
          console.log(data)
          if (data.response) {
            this.$store.dispatch('fd_client/set_client', data.data)
          }
        })
    },
    async updateSelectedInvoice(status) {
      let payload = {
        client_id: this.get_client.client_id,
        use_uploaded: status
      }
      console.log(payload)
      await this.$axios.post('auth/fd_client/create_or_update_invoice', payload)
        .then(({ data }) => {
          console.log(data)
          if (data.response) {
            this.$store.dispatch('fd_client/get_clients')
            this.refreshClient()
          }
        })
    },
    viewInvoice() {
      console.log(this.get_client.invoice.encrypted_id)
      this.$router.push({ name: 'View Invoice', params: { token: this.get_client.invoice.encrypted_id } })
    },
    async uploadInvoice() {
      const formData = new FormData()
      formData.append('client_id', this.get_client.client_id)
      formData.append('invoice_file', this.invoice_file)
      await this.$axios.post('auth/fd_client/upload_invoice', formData)
        .then(({ data }) => {
          console.log(data)
          this.showSnackBar(data.message)
          if (data.response) {
            this.$store.dispatch('fd_client/get_clients')
            this.refreshClient()
          }
        })
    },
    openInvoiceBuilderDialog() {
      this.invoice_builder_dialog = true
    },
    closeInvoiceBuilderDialog() {
      this.invoice_builder_dialog = false
    },
    cancel_dialog_cost_estimate() {
      this.dialog_cost_estimate = false
      this.calculate_cost()
      this.calculate_preview()
    },
    async deleteClient() {
      //this.dialog_delete_client = false
      let payload = {
        client_id: this.get_client.id,
        user_id: this.get_client.client_id
      }
      await this.$axios.post('/auth/fd_client/delete_client', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch('fd_client/get_clients')
              .then(() => {
                this.$router.push({ name: '/my-clients' })
              })
          }
        })
    },
    view_document(item) {
      window.open(this.api_url + 'frontend/client_documents/' + item.file_name + '.' + item.extension, '_blank')
    },
    async copy_funeral_payment_link() {
      let payload = {
        user_id: this.get_client.get_user_info.id
      }
      await this.$axios.post('/auth/fd_client/check_encrypted_id', payload)
        .then(({ data }) => {
          if (data.response) {
            const link = this.app_url + '/funeral-payment/' + data.data.encrypted_id
            this.copy_payment_link(link)
          }
        })
    },
    async copy_payment_link(link) {
      try {
        await navigator.clipboard.writeText(link)
        this.showSnackBar('Copied!')
      } catch ($e) {
        this.showSnackBar('Cannot copy')
      }
    },
    async refreshTransactions(refresh) {
      let payload = {
        user_id: this.get_client.get_user_info.id,
        billingemail: this.get_client.get_user_info.email
      }

      if (refresh || this.get_client.get_user_info.get_payments.length == 0) {
        await this.$axios.get('auth/fd_client/get_transactions', payload)
          .then(({ data }) => {
            if (data.response) {
              this.$store.dispatch('fd_client/get_clients')
              this.refreshClient()
              if (refresh) {
                this.showSnackBar('Refreshed data successfully')
              }
            }
            this.client_transactions = this.get_client.get_user_info.get_payments[0].transaction_history.responses[0].records
            if (this.client_transactions != null && this.client_transactions.length > 0) {
              this.client_transactions.forEach((payment, index) => {
                if (payment.settlestatus != 100) {
                  this.client_transactions.splice(index, 1)
                }
                else {
                  payment.baseamountactual = payment.baseamount / 100
                  payment.settlebaseamountactual = payment.settlebaseamount / 100
                }
              })
            }
          })
      }
      else {
        this.client_transactions = this.get_client.get_user_info.get_payments[0].transaction_history.responses[0].records
        if (this.client_transactions != null && this.client_transactions.length > 0) {
          this.client_transactions.forEach((payment, index) => {
            if (payment.settlestatus != 100) {
              this.client_transactions.splice(index, 1)
            }
            else {
              payment.baseamountactual = payment.baseamount / 100
              payment.settlebaseamountactual = payment.settlebaseamount / 100
            }
          })
        }
      }
    },
    calculate_cost() {
      this.remainder = this.get_client.funeral_cost - this.deposit
      let remainder_plus_interest = this.admin_charge * this.remainder + this.remainder
      this.payment_breakdown = [
        //{ text: "Deposit", value: '€' + this.deposit },
        //{ text: "Balance Remaining", value: '€' + this.remainder },
        { text: "Spread Cost Admin Fee", value: this.admin_charge * 100 + '%' },
        { text: "Total", value: '€' + this.admin_charge * this.remainder },
        //{ text: "Balance Remaining", value: '€' + remainder_plus_interest },
      ]

      if (this.get_client.get_payment_term) {
        let monthly = remainder_plus_interest / this.get_client.get_payment_term.duration
        monthly = monthly.toFixed(0)
        this.payment_term_breakdown = [
          { text: "Monthly Payment", value: "€" + monthly },
        ]
      }
    },
    calculate_preview() {
      let remainder_plus_interest = this.admin_charge * this.remainder + this.remainder
      this.preview_amount = remainder_plus_interest / this.preview_duration
      this.preview_amount = this.preview_amount.toFixed(0)
    },
    view_funeral_notice() {
      window.open('/funeral-notice/' + this.get_client.get_funeral_notice.link)
    },
    async copy_funeral_notice_link() {
      const data = this.app_url + "/funeral-notice/" + this.get_client.get_funeral_notice.link
      try {
        await navigator.clipboard.writeText(data)
        this.showSnackBar("Copied!")
      } catch ($e) {
        this.showSnackBar("Cannot copy")
      }
    },
    open_edit_funeral_notice() {
      this.dialog_edit_funeral_notice = true
      this.funeral_notice = this.get_client.get_funeral_notice.notice
    },
    async edit_funeral_notice() {
      let payload = {
        user_id: this.get_client.get_user_info.id,
        template_id: this.get_client.get_funeral_notice.template_id,
        notice: this.funeral_notice,
      }
      await this.$axios.post('/auth/admin/funeral_notice/save_funeral_notice', payload)
        .then(({ data }) => {
          if (data.response) {
            this.dialog_edit_funeral_notice = false
            this.showSnackBar("Saved successfully")
            this.get_client.get_funeral_notice = data.data
          }
        })
    },
    open_dialog_edit_client() {
      this.dialog_edit_client = true
    },
    cancel_dialog_edit_client() {
      this.dialog_edit_client = false
    },
    open_dialog_edit_status(client) {
      this.dialog_edit_status = true
      this.to_edit_status.schedule = this.$date(client.schedule).format("YYYY-MM-DD HH:mm:ss")
      this.to_edit_status.schedule_am_pm = (client.schedule != null) ? this.$date(client.schedule).format("YYYY-MM-DD hh:mm:ss a") : null
      this.to_edit_status.action = client.action
      this.to_edit_status.id = client.id
    },
    cancel_dialog_edit_status() {
      this.dialog_edit_status = false
    },
    close_schedule_modal() {
      this.schedule_modal = false
      this.to_edit_status.schedule_am_pm = (this.to_edit_status.schedule != null) ? this.$date(this.to_edit_status.schedule).format("YYYY-MM-DD hh:mm:ss a") : null
    },
    async edit_schedule() {
      let payload = {
        id: this.to_edit_status.id,
        schedule: this.$date(this.to_edit_status.schedule).format("YYYY-MM-DD HH:mm:ss"),
        action: this.to_edit_status.action
      }

      await this.$axios.patch('/auth/fd_client/edit_fd_client', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch("fd_client/set_client", data.client);
            this.cancel_dialog_edit_status()
          }
        })
    },
    async change_client_status(client_id, new_status_id) {
      let payload = {
        id: client_id,
        status_id: new_status_id
      }
      await this.$axios.patch('/auth/fd_client/edit_fd_client', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$store.commit("fd_client/set_clients", data.data);
          }
        })
    },
    open_notes() {
      this.edit_notes = true
      this.notes_to_edit = this.get_client.notes
    },
    async save_notes() {
      let payload = {
        id: this.get_client.id,
        notes: this.notes_to_edit
      }
      await this.$axios.patch('/auth/fd_client/edit_fd_client', payload)
        .then(({ data }) => {
          if (data.response) {
            this.edit_notes = false
            this.$store.dispatch("fd_client/set_client", data.client);
          }
        })
    },
    open_tags_dialog() {
      this.dialog_edit_tags = true
      this.tags_to_edit = this.get_client.tags
    },
    close_tags_dialog() {
      this.dialog_edit_tags = false
    },
    async save_tags() {
      let payload = {
        id: this.get_client.id,
        tags: this.tags_to_edit
      }
      await this.$axios.patch('/auth/fd_client/edit_fd_client', payload)
        .then(({ data }) => {
          if (data.response) {
            this.close_tags_dialog()
            this.$store.dispatch("fd_client/set_client", data.client);
          }
        })
    },
    async upload_document() {
      const formData = new FormData()
      formData.append('client_id', this.get_client.id)
      formData.append('document', this.document_to_upload)
      formData.append('description', this.document_description)
      await this.$axios.post('auth/client/files/upload_document', formData)
        .then(({ data }) => {
          this.showSnackBar(data.message)
          if (data.response) {
            this.dialog_add_document = false
            this.document_to_upload = null
            this.document_description = ''
            this.refreshClient()
          }
        })
    },
    edit_document_dialog(item) {
      this.dialog_edit_document = true
      this.to_edit.id = item.id
      this.to_edit.original_name = item.original_name
      this.to_edit.description = item.description
    },
    cancel_edit_document_dialog() {
      this.dialog_edit_document = false
    },
    async edit_document() {
      let payload = {
        id: this.to_edit.id,
        original_name: this.to_edit.original_name,
        description: this.to_edit.description
      }
      await this.$axios.patch('auth/client/files/edit_document', payload)
        .then(({ data }) => {
          this.showSnackBar(data.message)
          if (data.response) {
            this.cancel_edit_document_dialog()
            this.refreshClient()
          }
        })
    },
    async delete_document(item) {
      await this.$axios.delete(`auth/client/files/delete_document/${item.id}`)
        .then(({ data }) => {
          this.showSnackBar(data.message)
          if (data.response) {
            this.refreshClient()
          }
        })
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss">
th {
  border-bottom: none !important;
}

// remove border
::v-deep .v-input__control>.v-input__slot:before,
::v-deep .v-input__control>.v-input__slot:after {
  border-style: none !important;
}

.status_0,
.status_1,
.status_2,
.status_3,
.status_4,
.status_5 {
  justify-content: center;
}

.status_1 {
  background-color: #DEF3FF !important;
  color: #347CE9 !important;
}

.status_2 {
  background-color: #FFFADE !important;
  color: #172C54 !important;
}

.status_3 {
  background-color: #FFEEE4 !important;
  color: #CE6300 !important;
}

.status_4 {
  background-color: #FAFAFA !important;
  color: #347CE9 !important;
}

.status_5 {
  background-color: #F1FFEC !important;
  color: #219226 !important;
}

.desktop_card {
  padding-left: 50px;
  padding-right: 50px;
}

::v-deep .v-slide-group__prev {
  display: none !important;
}
</style>
